import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ConsumerLoginComponent, ConsumerLoginModule, CONSUMER_LOGIN_APP_CONFIG } from '@por/consumer-login/ui-lib';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [CommonModule, ConsumerLoginModule],
    bootstrap: [AppComponent],
    providers: [{ provide: CONSUMER_LOGIN_APP_CONFIG, useValue: environment }]
})
export class AppModule implements DoBootstrap {
    constructor(private readonly injector: Injector) {}

    ngDoBootstrap(): void {
        const entryPoint = createCustomElement(ConsumerLoginComponent, {
            injector: this.injector
        });
        customElements.define('por-consumer-login', entryPoint);
    }
}
