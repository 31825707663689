import { Component } from '@angular/core';
import { Auth } from '@por/consumer-login/ui-lib';
import { LoggerService } from './service/logger.service';

@Component({
    selector: 'por-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'consumer-login-host-app';
    isAuthenticated = false;

    constructor(private readonly logger: LoggerService) {}
    config = JSON.stringify({
        logoUrl: 'https://dev-ecom-consumer-portal-admin.rentamation.com/assets/images/POR_eCommerce_logo.svg',
        faviconUrl: 'https://dev-ecom-consumer-portal-admin.rentamation.com/assets/images/POR_eCommerce_logo.svg',
        organizationId: '3070',
        styling: {
            busyIndicator: {
                header: {},
                paragraph: {},
                input: {},
                button: {},
                anchor: {}
            },
            changePassword: {
                header: {},
                paragraph: {},
                input: {},
                button: {},
                anchor: {}
            },
            forgotPassword: {
                header: {},
                paragraph: {},
                input: {},
                button: {},
                anchor: {}
            },
            signin: {
                header: {},
                paragraph: {},
                input: {},
                button: {},
                anchor: {}
            },
            signup: {
                header: {},
                paragraph: {},
                input: {},
                button: {},
                anchor: {}
            },
            verify: {
                header: {},
                paragraph: {},
                input: {},
                button: {},
                anchor: {}
            },
            themeColors: {}
        }
    });

    handleLoginSuccessData(authData: Auth) {
        this.isAuthenticated = true;
        this.logger.logWarning(authData);
    }
}
